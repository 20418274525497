// Styles
import '../icons/style.css';
import 'toastify-js/src/toastify.css';
import 'tippy.js/dist/tippy.css';
import 'zoomist/css';
import '../css/modules.scss';
import '../css/app.css';

// For Validation
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isJSON from 'validator/lib/isJSON';

// Custom Plugins
import ScrollIneractive from './modules-custom/ScrollIneractive';
import ScrollHeader from './modules-custom/ScrollHeader';
import ScrollSmooth from './modules-custom/ScrollSmooth';
import ScrollToTop from './modules-custom/ScrollToTop';
import SlideShow from './modules-custom/SlideShow';
import ShowMore from './modules-custom/ShowMore';
import { ShowMoreLC } from './modules-custom/ShowMore';
import RangeSelect from './modules-custom/RangeSelect';
import VideoAutoplay from './modules-custom/VideoAutoplay';
import FloatSidebar from './modules-custom/FloatSidebar';
import Tooltip from './modules-custom/Tooltip';
import Burger from './modules-custom/Burger';
import Tabs from './modules-custom/Tabs';
import Accordion from './modules-custom/Accordion';
import RequestQueue from './modules-custom/RequestQueue';
import { W8ShippingAPIClient } from './modules-custom/W8_ShippingAPIClient';

// NPM Plugins
import '@iconfu/svg-inject';
import Cookies from 'js-cookie';
import MicroModal from 'micromodal';
import Toastify from 'toastify-js';

// Some Usefull Functions
import {
  screens,
  onShowMicroModal,
  onCloseMicroModal,
  refreshFragments,
  sendEventDataToGA4,
} from './modules-custom/functions';

import getOperatorCode from './functions/getOperatorCode';

// Declarete WP functions
const { translations } = theme;
let IMask = null;

// Example usage:
const queues = {
  watchlist: new RequestQueue(),
  compare: new RequestQueue(),
};

const client = new W8ShippingAPIClient(
  theme.api.endpointW8,
  theme.api.nonce,
  theme.i18n.locale_pll,
);

// Встановлюємо ширину скролбара
setScrollBarWidth();

document.addEventListener('turnstile-loaded', function () {
  document
    .querySelectorAll('form[data-form-callback] button[disabled]')
    ?.forEach((element) => {
      element.disabled = false;
    });
});
document.addEventListener('compare-add', () => {
  new ScrollHeader('CompareSlider');
});
document.addEventListener('compare-remove', () => {
  new ScrollHeader('CompareSlider');
});
document.addEventListener('DOMContentLoaded', () => {
  // Меню
  window.burger = new Burger();

  document.querySelectorAll('.VideoAutoplay')?.forEach((el) => {
    new VideoAutoplay(el);
  });

  // Замінюємо всі img з класом style-svg на svg, з затримкою в 1500мс
  replaceSVG(1500);

  // Встановлюємо висоту хедеру
  setHeaderHeight();

  new ScrollIneractive('LineScroll');

  // Додаємо класс active коли елемент стає Sticy
  new ScrollHeader('BtnToggleFilter');
  new ScrollHeader('CompareSlider');

  // Слайдшоу
  new SlideShow('.SlideShow', 3000);

  // Модальні вікна
  setPopups();

  // Скидаємо кастомне повідомлення про помилку валідації
  document
    .querySelectorAll('input[name="Email"], input[name="MobilePhone"]')
    ?.forEach((el) => {
      el.addEventListener('input', () => {
        el.setCustomValidity('');
        el.checkValidity();
      });
    });

  // Перемикач FormID та SalesChannel при зміні кнопки
  document.querySelectorAll('.SalesChannel')?.forEach((el) => {
    el.addEventListener('click', () => {
      const form = el.closest('form');
      // remove active from all elements
      form
        .querySelectorAll('.SalesChannel')
        .forEach((el) => el.classList.remove('active'));
      // add active to current element
      el.classList.add('active');

      // set formID to input
      const form_id = form.id;
      const inputFormID = document.getElementById(`${form_id}-FormID`);
      const inputSalesChannel = document.getElementById(
        `${form_id}-SalesChannel`,
      );
      try {
        inputFormID.value = el.dataset.formid;
        inputSalesChannel.value = el.dataset.saleschannel;
      } catch (error) {
        console.error(error);
      }
    });
  });

  // Встановимо залежність пар селектів. Щоб унеможливити вибір діапозонів типу від 2018 до 2014 року
  new RangeSelect('.RangeSelect');

  // Плаваючий сайдбар
  new FloatSidebar(
    document.getElementById('sidebar'),
    document.getElementById('content'),
  );

  // Показати/Сховати контент
  document.querySelectorAll('.ShowMore')?.forEach((el) => {
    new ShowMore(el);
  });
  document.querySelectorAll('.ShowMoreLC')?.forEach((el) => {
    new ShowMoreLC(el);
  });

  // Маска на інпути
  setTimeout(() => {
    downloadMasks().then(setMasks);
  }, 1000);

  // Тултипи
  new Tooltip();

  // Скролл до наступного секції після кліку по кнопці.
  setScrollToNextSection();

  // Встановимо обробники форм
  setFormsHendler();

  // Вкладки
  new Tabs();

  // Плавний скролл до секції
  const header = document.getElementById('header');
  const burgerNavigation = document.getElementById('burgerNavigation');
  if (header && burgerNavigation) {
    new ScrollSmooth({
      headerHeight: document.getElementById('header').clientHeight,
      navigationHeight:
        document.getElementById('burgerNavigation').clientHeight,
    });
  }

  // Кнопка Вверх
  new ScrollToTop({
    targetSelector: '.aioseo-breadcrumbs, main > *',
    scrollToTopButtonId: 'btn-back-to-top-desktop',
  });
  new ScrollToTop({
    targetSelector: '.aioseo-breadcrumbs, main > *',
    scrollToTopButtonId: 'btn-back-to-top-mobile',
  });

  // Open Filter Button
  document.querySelectorAll('.BtnToggleFilter')?.forEach((el) => {
    el.addEventListener('click', () => {
      window.showFilterBlock();
    });
  });

  // Set tabs on team page
  window.aboutTeamCityList();

  // Accordion
  document.querySelectorAll('details')?.forEach((el) => {
    new Accordion(el);
  });

  // Analitycs. Відстежуємо помилки на інпутах
  setInputErrorListenner();

  // Set Default Modal Data
  window.defaultModaLdata = {};
  document.querySelectorAll('.modal').forEach((modal) => {
    window.defaultModaLdata[`${modal.id}`] = {
      title: document.getElementById(`${modal.id}-title`)?.innerText,
      text: document.getElementById(`${modal.id}-text`)?.innerText,
      btn: document.getElementById(`${modal.id}-btn`)?.innerText,
    };
  });

  if (document.querySelectorAll('.zoomist-container').length > 0) {
    setZoomist();
  }

  document.querySelectorAll('img')?.forEach((el) => {
    el.addEventListener(
      'error',
      () => (el.src = theme.directory + '/assets/img/placeholder-auto.jpg'),
    );
  });

  document.querySelectorAll('[href="#modal"]').forEach((el) =>
    el.addEventListener('click', () => {
      window.setOpenModalWithData(el);
    }),
  );

  function __addStyleToButtonCusbo() {
    const cusboWidget = document.querySelector('app-cusbo-widget');
    const cusboRoot = cusboWidget.shadowRoot;
    const button = cusboRoot?.querySelector('app-widget-button');

    if (cusboWidget && cusboRoot && button) {
      const style = document.createElement('style');
      style.innerHTML = `@media (max-width: 640px) {
				.button {
					bottom: 64px !important;
				}
			}`;
      cusboRoot.appendChild(style);
      return true;
    }

    return false;
  }

  function __addStyleToButton() {
    const binotelWidget = document.querySelector('#bingc-phone-button');
    const dialongWidget = document.getElementById('dialong-webchat');
    const dialongRoot = dialongWidget?.firstElementChild.shadowRoot;
    const button = dialongRoot?.querySelector('.dchat-bubble');

    if (binotelWidget && dialongWidget && dialongRoot && button) {
      dialongWidget.classList.add('chat-widget-visible');
      binotelWidget.classList.add('binotel-widget-visible');

      const style = document.createElement('style');
      style.innerHTML = `
				@media (max-width: 640px) {
					.dchat-bubble {
						bottom: calc(64px + env(safe-area-inset-bottom));
					}
					#dchat-popup {
						bottom: calc(139px + env(safe-area-inset-bottom));
					}
				}
				.dchat-bubble {
					right: 24px;
				}
				.dchat-operator-photo {
					flex: none;
				}
			`;
      dialongRoot.appendChild(style);
      return true;
    }

    return false;
  }

  function addStyleToButton() {
    if (theme.is_kz) {
      return __addStyleToButtonCusbo();
    } else {
      return __addStyleToButton();
    }
  }

  function tryAddingStyle() {
    const success = addStyleToButton();
    if (success) {
      observer.disconnect(); // Отключаем observer, если все удалось
    } else {
      setTimeout(tryAddingStyle, 100);
    }
  }

  // Створення нового MutationObserver
  const observer = new MutationObserver((mutationsList) => {
    // Перевірка кожної зміни у DOM
    for (let mutation of mutationsList) {
      // Перевірка, чи є додавання нових елементів у DOM
      if (mutation.type === 'childList') {
        // Перевірка наявності обидвох елементів після додавання
        const binotelWidget = document.getElementById('bingc-phone-button');
        const dialongWidget = document.getElementById('dialong-webchat');
        const cusboWidget = document.querySelector('app-cusbo-widget');

        if ((binotelWidget && dialongWidget) || cusboWidget) {
          // Якщо обидва елементи знайдені, викликати функцію
          tryAddingStyle();
          break;
        }
      }
    }
  });

  // Налаштування MutationObserver для спостереження за змінами у DOM
  observer.observe(document.body, { childList: true, subtree: true });
  // addStyleToButton(); // Первоначальная попытка добавить стили, если виджет уже на странице

  // Cookie Pop Up
  // setPrivacyPolicyHendler();
  // if (Cookies.get("PrivacyPolicyAllowed") != "true") {
  // 	setTimeout(() => {
  // 		showPrivacyPolicyPopPup();
  // 	}, 3000);
  // }

  document.querySelectorAll('.TooglePassword')?.forEach((el) => {
    el.addEventListener('click', () => {
      const input = el.previousElementSibling;
      if (input.type === 'password') {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
    });
  });
});
document.addEventListener('swiper-reviews-updated', () => {
  // Спрацьовує при оновленні слайдів свайпера
  document.querySelectorAll('.ShowMoreLC')?.forEach((el) => {
    if (!el.showMore) {
      new ShowMoreLC(el);
    }
  });
});
document.addEventListener('calculated', function () {
  // Маска на інпути
  if (IMask) {
    setMasks(IMask);
  } else {
    downloadMasks().then(setMasks);
  }
});
document.addEventListener('facetwp-loaded', function () {
  // Маска на інпути
  if (IMask) {
    setMasks(IMask);
  } else {
    downloadMasks().then(setMasks);
  }

  // Встановимо залежність пар селектів. Щоб унеможливити вибір діапозонів типу від 2018 до 2014 року
  new RangeSelect('.RangeSelect');

  // Встановимо обробники форм
  document
    .querySelectorAll('.facetwp-template [data-form-callback]')
    ?.forEach((el) => sendFormData(el));

  // Переклад placeholder в інпутах пошуку
  document.querySelectorAll('.fs-search input')?.forEach((el) => {
    el.placeholder = translations.search;
  });

  // Підрахунок кількості лотів в фільтрі "Майбутні лоти"
  if (document.querySelector('.facetwp-facet-future_lot .facetwp-counter')) {
    // Отримання числа з блоку N1
    const blockN1 = document.querySelector('.facetwp-facet-result_counts');
    const countN1 = parseInt(blockN1.innerText.match(/\d+/)[0]);

    // Отримання числа з блоку N2
    const blockN2 = document.querySelector(
      '.facetwp-facet-future_lot .facetwp-counter',
    );
    document
      .querySelector('.facetwp-facet-future_lot')
      .classList.remove('hidden');

    const countN2 = parseInt(blockN2.innerText.match(/\d+/)[0]);

    // Обчислення різниці
    const difference = countN1 - countN2;

    // Заміна числа в блоку N2 на різницю
    blockN2.innerText = '(' + difference + ')';

    // Додавання класу 'hidden', якщо різниця дорівнює 0
    if (difference === 0) {
      document
        .querySelector('.facetwp-facet-future_lot')
        .classList.add('hidden');
    }
  }

  // Зміна href на посиланнях переходу на іншу мову
  changeFilterUrl();

  // Робимо неактивним селект моделі, якщо не вибрано марку
  document
    .querySelector('.facetwp-facet-model .fs-wrap')
    ?.classList.add('disabled');
  if (typeof FWP.facets['make'] !== 'undefined' && FWP.facets['make'].length) {
    document
      .querySelector('.facetwp-facet-model .fs-wrap')
      ?.classList.remove('disabled');
  }
});
window.addEventListener('resize', () => {
  setScrollBarWidth();
  setHeaderHeight();
});

function setZoomist() {
  setTimeout(async () => {
    const ZoomistModule = await import('zoomist');
    const Zoomist = ZoomistModule.default;
    new Zoomist('.zoomist-container', {
      slider: true,
      zoomer: true,
      wheelable: false,
    });
  }, 2000);
}

function isAnyModalOpen() {
  let isAnyModalOpen = false;
  document.querySelectorAll('.modal')?.forEach((el) => {
    if (el.classList.contains('is-open')) {
      isAnyModalOpen = true;
    }
  });
  return isAnyModalOpen;
}

function setPopups() {
  MicroModal.init();

  // TODO Якщо клікнути по TomSelect, то не спрацьовує cancelModal
  if (
    theme.page.is_catalog &&
    !theme.is_kz &&
    !localStorage.getItem('showCloseContent')
  ) {
    // Модальне вікно на сторінці "Catalog"
    const timeOutId = setTimeout(() => {
      if (!isAnyModalOpen()) {
        MicroModal.show('modal-form-await', {
          disableScroll: true,
          onShow: onShowMicroModal,
          onClose: onCloseMicroModal,
          awaitOpenAnimation: true,
          // awaitCloseAnimation: true,
        });
      }
    }, 15000);

    const cancelModal = () => {
      clearTimeout(timeOutId);
      document.removeEventListener('click', cancelModal); // Видаляємо обробник подій після скасування
    };

    document.addEventListener('click', cancelModal);
  }

  if (
    document.getElementById('modal-form-await-promo') &&
    typeof localStorage !== 'undefined' &&
    !localStorage.getItem('promo-popap-shown-1')
  ) {
    setTimeout(() => {
      if (!isAnyModalOpen() && !localStorage.getItem('showCloseContent')) {
        MicroModal.show('modal-form-await-promo', {
          disableScroll: true,
          onShow: onShowMicroModal,
          onClose: onCloseMicroModal,
          awaitOpenAnimation: true,
        });
        localStorage.setItem('promo-popap-shown-1', '1');
      }
    }, 20_000);
  }

  if (theme.is_kz && !Cookies.get('dontShowModalFormAwaitKz')) {
    const showModal = () => {
      setTimeout(() => {
        if (!isAnyModalOpen()) {
          MicroModal.show('modal-form-await-kz', {
            disableScroll: true,
            onShow: onShowMicroModal,
            onClose: (modal) => {
              onCloseMicroModal(modal);
              Cookies.set('dontShowModalFormAwaitKz', true, { expires: 1 });
            },
            awaitOpenAnimation: true,
            // awaitCloseAnimation: true,
          });
        }
      }, 30000);
      document.removeEventListener('scroll', showModal); // Видаляємо обробник подій після скасування
    };

    document.addEventListener('scroll', showModal);
  }

  document.querySelectorAll('[data-btn-watchlist]')?.forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      MicroModal.show('modal-watchlist', {
        disableScroll: true,
        onShow: onShowMicroModal,
        onClose: onCloseMicroModal,
        awaitOpenAnimation: true,
        // awaitCloseAnimation: true,
      });
    });
  });

  document.querySelectorAll('.js-search-start')?.forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      MicroModal.show('modal-search', {
        disableScroll: true,
        onShow: function (modal) {
          onShowMicroModal(modal);
          document.getElementById('search').tomselect.focus();
        },
        onClose: function (modal) {
          onCloseMicroModal(modal);
          // document.querySelector("#search-form-placeholder input").blur()
        },
        awaitOpenAnimation: true,
        disableFocus: true,
        // awaitCloseAnimation: true,
      });
    });
  });
}

function replaceSVG(delay) {
  setTimeout(() => {
    SVGInject(document.querySelectorAll('img.style-svg'));
  }, delay);
}

function setScrollToNextSection() {
  document
    .querySelectorAll('[data-btn-scroll-to-next-section]')
    ?.forEach((el) => {
      el.addEventListener('click', () => {
        const currentSection = el.closest('section');
        const nextSection = currentSection.nextElementSibling;
        nextSection.scrollIntoView({ behavior: 'smooth' });
      });
    });
}

async function downloadMasks() {
  if (!IMask) {
    const IMaskModule = await import('imask');
    IMask = IMaskModule.default;
  }
  return IMask;
}

function setMasks(IMask) {
  const masks = {
    'pl-PL': [
      {
        mask: '000 000 000',
      },
    ],
    'kk-KZ': [
      {
        mask: '+7 000 000 00 00',
      },
      {
        mask: '8 000 000 00 00',
      },
    ],
    'uk-UA': [
      {
        mask: '+{38\\0} 00 00 00 000',
      },
      {
        mask: '+{38} (\\000) 00 00 000',
      },
    ],
  };

  const data = [
    {
      name: 'tel',
      inputs: document.querySelectorAll('input[name="MobilePhone"]'),
      options: {
        mask: masks[theme.i18n.locale],
      },
    },
    {
      name: 'name',
      inputs: document.querySelectorAll('input[name="Contact"]'),
      options: {
        mask: [
          {
            mask: /^[A-Za-zĄĆĘŁŃÓŚŹŻАąćęłńóśźż-ЯҐЄІЇЙК-ЩЬЮЯЁЪЫЭӘҒҚҢӨҰҮҺабвгґдеєжзиіїйк-щьюяёъыэәғқңөұүһ\s]+$/,
          },
        ],
      },
    },
    {
      name: 'engine',
      inputs: document.querySelectorAll('[data-input-engine]'),
      options: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            max: 20000,
            thousandsSeparator: ' ',
          },
        },
      },
    },
    {
      name: 'price',
      inputs: document.querySelectorAll(
        'input[data-input-bid], input[data-input-price]',
      ),
    },
  ];

  for (const item of data) {
    let { options } = item;

    if (!item.inputs) {
      continue;
    }

    item.inputs.forEach((el) => {
      if (el.masked) {
        return false;
      }

      if (item.name === 'price') {
        options = {
          mask: '$num',
          blocks: {
            num: {
              mask: Number,
              max: el.dataset.max ? +el.dataset.max : 10000000,
              min: el.dataset.min ? +el.dataset.min : 0,
              thousandsSeparator: ' ',
            },
          },
        };
      }

      if (theme.is_pl && item.name === 'tel') {
        el.addEventListener('input', () => {
          el.parentElement.classList.remove('valid');
          const iti = window.intlTelInputGlobals.getInstance(el);
          const value = '+' + el.value.replace(/\D/g, '');
          const countryData = iti.getSelectedCountryData();
          const phoneStarts = '+' + countryData.dialCode;
          const mask = intlTelInputUtils
            .getExampleNumber(
              countryData.iso2,
              true,
              intlTelInputUtils.numberType.MOBILE,
            )
            ?.replace(/\d/g, '0');

          const masked = IMask.pipe(value.replace(phoneStarts, ''), {
            mask: mask,
          });

          iti.setNumber(masked);

          if (iti.isValidNumber()) {
            el.parentElement.classList.add('valid');
          }
        });
      } else {
        const mask = IMask(el, options);
        if (item.name === 'tel') {
          mask.on('accept', function () {
            const value = mask.value.replace(/\D/g, '');
            const operator = getOperatorCode(value);

            if (isMobilePhone(value, theme.i18n.locale) && operator) {
              el.parentElement.classList.add('valid');
            } else {
              el.classList.remove('valid');
              el.parentElement.classList.remove('validblock');
            }
          });
          el.addEventListener('input', function () {
            const value = mask.value.replace(/\D/g, '');
            const operator = getOperatorCode(value);
            if (!(isMobilePhone(value, theme.i18n.locale) && operator)) {
              el.parentElement.classList.remove('valid');
            }
          });
        }
        el.mask = mask;
      }
    });
  }

  return true;
}

/**
 *	Встановлюємо CSS змінні висоти хедеру
 *  --header-height
 *  --header-mobile-bottom-height
 *	@returns {void}
 */
function setHeaderHeight() {
  const headers = [
    document.getElementById('header'),
    document.getElementById('header-mobile-bottom'),
  ];
  headers.forEach((el) => {
    if (el === null) {
      return false;
    }
    const prevWidth = document.documentElement.style.getPropertyValue(
      `--${el.id}-height`,
    );
    const newWidth = `${el.clientHeight}px`;
    if (newWidth !== prevWidth) {
      document.documentElement.style.setProperty(`--${el.id}-height`, newWidth);
    }
  });
  return true;
}

function changeFilterUrl() {
  const currentUrl = window.location.href;
  // знайти всі посилання з атрибутами "lang" та "href"
  const links = document.querySelectorAll('a[lang][href]');

  // оновити значення атрибуту "href" на оновлений URL
  links.forEach((link) => {
    const url = new URL(link.getAttribute('href'), currentUrl);
    url.search = new URLSearchParams(new URL(currentUrl).search);
    link.setAttribute('href', url.toString());
  });
}

function wrapTextInSpan(text) {
  return text.replace(/\{([^}]+)\}/g, '<span>$1</span>');
}

function setFormsHendler() {
  document
    .querySelectorAll('[data-form-callback]')
    .forEach((el) => sendFormData(el));
  document
    .querySelectorAll('[data-form-email-subscribe]')
    .forEach((el) => sendFormData(el));
  // document.querySelectorAll("[data-form-review]").forEach((el) => sendFormData(el));
}

function sendFormData(el) {
  if (el.sendFormData) {
    return;
  }
  el.addEventListener('submit', (e) => {
    e.preventDefault();

    const openedAt = Date.now(); // Зберігаємо час початку відправлення форми
    const loadingElements = el.querySelectorAll('[type="submit"]'); // Елементи на яких буде лоадер
    const formData = new FormData(el); // Дані з форми
    const formID = el.id; // Ідентифікатор форми
    const formName = document
      .getElementById(`${formID}-title`)
      ?.innerText.replace(/(<([^>]+)>)/gi, '')
      .trim()
      .toLowerCase(); // Якщо є, то заголовок форми

    const hasEmail = formData.has('Email') && formData.get('Email') != '';
    const hasMobilePhone =
      formData.has('MobilePhone') && formData.get('MobilePhone') != '';

    if (formData.get('Email') === '') {
      formData.delete('Email');
    }

    if (formData.get('MobilePhone') === '') {
      formData.delete('MobilePhone');
    }

    if (theme.is_pl && !(hasEmail || hasMobilePhone)) {
      el.querySelectorAll('[name="Email"], [name="MobilePhone"]').forEach(
        (el) => el.closest('.input').classList.add('pay-attention'),
      );

      document
        .getElementById(`${formID}-pay-attention`)
        ?.classList.remove('hidden');

      sendEventDataToGA4('field_error', {
        field_name: 'Email or MobilePhone',
        error_message: "don't filled",
      });

      return false;
    }

    if (hasEmail && !isEmail(formData.get('Email'))) {
      const input = el.querySelector('input[type="email"]');
      input.setCustomValidity(translations.email_invalid);
      input.reportValidity();

      sendEventDataToGA4('field_error', {
        field_name: input.getAttribute('name'),
        error_message: input.validationMessage,
      });
      return false;
    }

    if (hasMobilePhone) {
      const input = el.querySelector('input[name="MobilePhone"]');
      let locale = theme.i18n.locale;

      if (theme.is_pl) {
        try {
          const iti = window.intlTelInputGlobals.getInstance(input);
          const value = iti.getNumber(intlTelInputUtils.numberFormat.E164);
          formData.set('MobilePhone', value);
          const masks = {
            pl: 'pl-PL',
            kz: 'kk-KZ',
            ua: 'uk-UA',
          };
          const countryData = iti.getSelectedCountryData();
          const countryCode = countryData.iso2;
          locale = masks[countryCode] ?? 'any';
        } catch (error) {
          console.error(error);
        }
      }

      const MobilePhone = formData.get('MobilePhone');
      const operator = getOperatorCode(MobilePhone);
      const isItMobilePhone = isMobilePhone(
        MobilePhone.replace(/\D/g, ''),
        locale,
      );
      const notValid = !(isItMobilePhone && operator);

      if (notValid) {
        input.setCustomValidity(translations.phone_invalid);
        input.reportValidity();

        sendEventDataToGA4('field_error', {
          field_name: input.getAttribute('name'),
          error_message: input.validationMessage + ' ' + MobilePhone,
        });

        return false;
      }

      if (theme.is_kz && formData.get('MobilePhone').startsWith('8')) {
        formData.set(
          'MobilePhone',
          '+7' + formData.get('MobilePhone').slice(1),
        );
      }
    }

    if (!formData.has('SalesChannel')) {
      formData.append('SalesChannel', 'B2C');
    }

    client.post('lead', {
      body: Object.fromEntries(formData),
      loadingElements: loadingElements,
      onSuccess: ({ message, formType, leadID }) => {
        if (formType == 'lead') {
          localStorage.setItem('formData', Object.fromEntries(formData));
          localStorage.setItem('showCloseContent', '1');
          // Розрахунок часу очікування
          const waitingTime = Math.round((Date.now() - openedAt) / 1000); // у секундах
          sendEventDataToGA4('generate_lead', {
            form_id: formID,
            form_name: formName,
            lead_id: leadID,
            sales_channel: formData.get('SalesChannel'),
            link_text: formData.get('popup_btn'),
            phone_number: formData.has('MobilePhone')
              ? formData.get('MobilePhone').replace(/\s/g, '')
              : null,
            waiting_time: waitingTime,
          });

          if (window.fbq) {
            fbq('track', 'Lead');
          }

          if (window.clarity) {
            let clientID = formData.get('MobilePhone');

            if (!clientID) {
              clientID = formData.get('Email');
            }

            window.clarity(
              'identify',
              clientID,
              '',
              theme.page.id,
              formData.get('Contact'),
            );
          }

          document.querySelectorAll('.hidden-content')?.forEach((el) => {
            el.remove();
          });
        } else {
          sendEventDataToGA4('generate_' + formType);
        }
        onSuccess(null, message);
      },
      onError: ({ message, code }) => {
        sendEventDataToGA4('lead_unsuccess', {
          form_id: formID,
          form_name: formName,
          error_message: code ?? 'unkwon error',
          fatal: true,
        });
        console.error('Lead Unsuccess: ', code ?? 'unkwon error');
        onError(null, message ?? translations.error_message);
      },
    });
    return true;
  });
  el.sendFormData = true;
}

function closeModalIfOpen() {
  let closeModal = false;
  document.querySelectorAll('.modal')?.forEach((el) => {
    if (el.classList.contains('is-open')) {
      closeModal = true;
    }
  });
  if (closeModal) {
    MicroModal.close();
  }
  return true;
}

function onSuccess(title, message) {
  closeModalIfOpen();
  if (title) {
    const elementText = document.getElementById('modal-form-thankyou-title');
    elementText.innerHTML = title;
  }
  if (message) {
    const elementText = document.getElementById('modal-form-thankyou-text');
    elementText.innerHTML = message;
  }
  MicroModal.show('modal-form-thankyou');
}

function onError(title, message) {
  closeModalIfOpen();
  if (title) {
    const elementText = document.getElementById('modal-form-error-title');
    elementText.innerHTML = title;
  }
  if (message) {
    const elementText = document.getElementById('modal-form-error-text');
    elementText.innerHTML = message;
  }
  MicroModal.show('modal-form-error');
}

function setInputErrorListenner() {
  document.querySelectorAll('button[type=submit]').forEach((el) => {
    el.addEventListener('click', () => {
      el.closest('form')
        ?.querySelectorAll('input')
        ?.forEach((el) => {
          if (el.checkValidity()) {
            return false;
          }
          sendEventDataToGA4('field_error', {
            field_name: el.getAttribute('name'),
            error_message: el.validationMessage,
          });
        });
    });
  });
}

function setScrollBarWidth(event, cssVariableName = '--twcb-scrollbar-width') {
  const prevWidth =
    document.documentElement.style.getPropertyValue(cssVariableName);
  const newWidth = `${window.innerWidth - document.body.clientWidth}px`;
  if (newWidth !== prevWidth) {
    document.documentElement.style.setProperty(cssVariableName, newWidth);
  }
}

async function toggleAction(btn, endpoint) {
  // Встановлюємо стан "завантаження" на кнопці
  btn.disabled = true;
  btn.classList.add('loading'); // Додайте свій стиль для стану завантаження

  const productNode = btn.closest('[data-id]');
  const lotID = productNode?.dataset.id;
  let action = '';
  if (productNode) {
    action = productNode.classList.contains('in-' + endpoint)
      ? 'remove'
      : 'add';
  } else {
    action = 'clear';
  }

  const formData = new FormData();
  formData.append('lot_id', lotID);
  formData.append('action', action);
  formData.append('lang', theme.i18n.language);

  if (theme.page.is_compare) {
    formData.append('is_compare', true);
  }

  // Prepare form data and headers
  const data = JSON.stringify(Object.fromEntries(formData.entries()));
  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-WP-Nonce': theme.api.nonce,
    'Accept-Language': theme.i18n.locale_pll,
  });

  queues[endpoint].addRequest(
    () =>
      fetch(`${theme.api.endpointW8}/${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: data,
      }).then((res) => res.json()),
    (data) => {
      document
        .querySelectorAll(`[data-id='${lotID}']:not(#related_lots)`)
        ?.forEach((el) => {
          el.classList.toggle('in-' + endpoint);
        });

      const counters = document.querySelectorAll(`[data-type="${endpoint}"]`);

      counters.forEach((el) => {
        el.dataset.count = data.count;
        el.parentNode.parentNode.classList.add('tada');
        setTimeout(() => {
          el.parentNode.parentNode.classList.remove('tada');
        }, 1000);
      });

      if (data.fragments && !window.location.toString().includes('?ids=')) {
        refreshFragments(data.fragments);
      }

      data.notices?.forEach((notice) => showToastifyMesage(notice));

      const event = new CustomEvent(`${endpoint}-${data.action}`, {
        detail: {
          lotID: productNode?.dataset.id,
          ...data,
        },
      });
      document.dispatchEvent(event);

      if (data.reload) {
        location.reload();
      }
    },
    (error) =>
      console.error('API Error at endpoint:', endpoint, 'Error:', error),
    btn,
  );
}

function showToastifyMesage(message, type = 'info', duration = 1500) {
  Toastify({
    text: message,
    className: type,
    duration: duration,
    stopOnFocus: true,
    close: true,
    avatar: theme.directory + `/assets/img/${type}.svg`,
    offset: {
      y: document.getElementById('header')?.clientHeight ?? 0,
    },
  }).showToast();
}

window.aboutTeamCityList = function () {
  const event = new CustomEvent('afterTabsListChanged');
  let aboutCityList = document.getElementById('about_city_list');
  if (!aboutCityList) {
    return;
  }
  let aboutCityListItems = aboutCityList.querySelectorAll('.city-item');
  let aboutCityListEmployers = document.querySelectorAll('.city-employers');
  aboutCityListItems.forEach((item) => {
    item.addEventListener('click', (e) => {
      aboutCityListItems.forEach((i) => {
        i.classList.remove('active');
      });
      e.target.classList.add('active');
      aboutCityListEmployers.forEach((i) => {
        i.classList.add('hidden');
        if (i.dataset.city === e.target.dataset.city) {
          i.classList.remove('hidden');
        }
      });
      document.dispatchEvent(event);
    });
  });

  if (window.location.search.includes('_type')) {
    const city = window.location.search.split('_type=')[1];
    aboutCityListItems.forEach((item) => {
      if (item.dataset.city === city) {
        item.click();
      }
    });
  }
};

// for be possible add prop "onclick" to btn
window.hideFilterBlock = function () {
  const sidebar = document.getElementById('sidebar');
  const sidebarFilter = document.getElementById('sidebar--filter');
  const sidebarOverlay = document.getElementById('sidebar--overlay');
  const body = document.getElementById('body');
  const footer = document.getElementById('footer');
  const header = document.getElementById('header');

  body.classList.remove('filter-opened', 'overflow-hidden');
  footer.classList.remove('-z-10');

  sidebarFilter.classList.remove('animated', 'bounceInLeft');

  sidebarOverlay.classList.remove('animated', 'fadeIn');
  sidebarFilter.classList.remove('animated', 'bounceInLeft');

  sidebarOverlay.classList.add('animated', 'fadeOut');
  sidebarFilter.classList.add('animated', 'bounceOutLeft');

  // Оголошуємо функцію перед використанням
  function onAnimationEnd() {
    sidebar.classList.add('hidden');
    if (window.innerWidth >= screens.lg) {
      header.classList.add('z-40');
    }
    sidebarOverlay.classList.remove('animated', 'fadeOut'); // Очищення анімаційних класів
    sidebarFilter.classList.remove('animated', 'bounceOutLeft');
    sidebarOverlay.removeEventListener('animationend', onAnimationEnd); // Видаляємо слухача
  }

  sidebarOverlay.addEventListener('animationend', onAnimationEnd);
};

window.showFilterBlock = function () {
  const sidebar = document.getElementById('sidebar');
  const sidebarFilter = document.getElementById('sidebar--filter');
  const sidebarOverlay = document.getElementById('sidebar--overlay');
  const body = document.getElementById('body');
  const footer = document.getElementById('footer');
  const header = document.getElementById('header');

  body.classList.add('filter-opened', 'overflow-hidden');
  footer.classList.add('-z-10');
  if (window.innerWidth >= screens.lg) {
    header.classList.remove('z-40');
  }

  sidebar.classList.remove('hidden');
  sidebarFilter.classList.add('animated', 'bounceInLeft');
  sidebarOverlay.classList.add('animated', 'fadeIn');
};

window.watchlist = async function (btn) {
  await toggleAction(btn, 'watchlist');
};

window.compare = async function (btn) {
  await toggleAction(btn, 'compare');
};

// data-btn-open-modal-form data-{що це?}-{що робить?}-{з ким робить?}-{Модифікатор}
window.setOpenModalWithData = function (el) {
  if (
    document.getElementById('modal-watchlist').classList.contains('is-open')
  ) {
    MicroModal.close('modal-watchlist');
  }

  let modalId;
  let attrs;

  if (el.getAttribute('href') === '#modal') {
    modalId = 'callback';
    attrs = {
      id: 'callback',
      texts: {
        title: translations['attrs.title'],
        text: translations['attrs.text'],
      },
    };
  } else {
    if (isJSON(el.dataset.btnOpenModalForm)) {
      attrs = JSON.parse(el.dataset.btnOpenModalForm);
      modalId = attrs.id;
    } else {
      modalId = el.dataset.btnOpenModalForm;
    }
  }

  document
    .getElementById(modalId)
    ?.querySelector('textarea')
    ?.setAttribute('value', '');

  if (attrs) {
    const { id, texts, values, lot } = attrs;
    if (texts) {
      for (const key in texts) {
        if (Object.hasOwnProperty.call(texts, key)) {
          const element = texts[key];
          if (element === '') {
            continue;
          }
          try {
            if (element === 'hidden') {
              document.getElementById(`modal-form-${id}-${key}`).innerHTML = '';
            } else {
              document.getElementById(`modal-form-${id}-${key}`).innerHTML =
                wrapTextInSpan(element);
            }
          } catch {
            console.error(`Error modal open: modal-form-${id}-${key}`);
          }
        }
      }
    }
    if (values) {
      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const element = values[key];
          try {
            document.getElementById(`modal-form-${id}-${key}`).value = element;
          } catch {
            console.error(`Error modal open: modal-form-${id}-${key}`);
          }
        }
      }
    }
    if (lot) {
      for (const key in lot) {
        if (Object.hasOwnProperty.call(lot, key)) {
          const element = lot[key];
          if (key == 'image') {
            document
              .getElementById(`modal-form-${id}-lot-image`)
              .setAttribute('src', element);
          } else {
            document.getElementById(`modal-form-${id}-lot-${key}`).innerHTML =
              element;
          }
        }
      }
    }
  }

  // Show Modal
  MicroModal.show('modal-form-' + modalId, {
    disableScroll: true,
    onShow: onShowMicroModal,
    onClose: onCloseMicroModal,
    awaitOpenAnimation: true,
    // awaitCloseAnimation: true,
  });
};

document.setBiaTvCookie = function () {
  let cookie = Cookies.get('biatv-cookie');
  // Check if the cookie exists and is not empty
  if (cookie) {
    try {
      cookie = JSON.parse(cookie);
      // Check if "utmDataCurrent" property exists in the cookie
      if (cookie && cookie.utmDataCurrent) {
        cookie = cookie.utmDataCurrent;

        // Remove the "beginning_at" property
        delete cookie.beginning_at;

        // Iterate through properties and set values if corresponding elements exist
        for (const key in cookie) {
          if (Object.hasOwnProperty.call(cookie, key)) {
            const element = document.getElementById(key);
            if (element && element.value === '(not_set)') {
              element.value = cookie[key];
            }
          }
        }
      } else {
        console.error("Missing 'utmDataCurrent' property in the cookie.");
      }
    } catch (error) {
      console.error('Error parsing cookie:', error);
    }
  } else {
    console.error("Cookie 'biatv-cookie' not found.");
  }
};

document.frontendaWriteText = function (element, text) {
  if (theme.page.is_compare) {
    text = text + '?ids=' + Cookies.get('compare');
  }
  navigator.clipboard.writeText(text).then(
    () => {
      element._tippy.show();
    },
    () => {},
  );
};

document.showToastifyMesage = showToastifyMesage;
